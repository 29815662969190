import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class TechnologyCn extends React.Component {

    render() {
        return (
            <section className="technology_cn">
              <section className="technology_content">
                {/*<img alt="" src={require('../../assets/img/technology_cn/technology_cn.png')}/>*/}
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/technology_cn/technology_cn.png')})`}}>
                  Abzed<br/>
                  独一无二的靶向免疫治疗平台，打造新医药行业核心竞争力
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/technology_cn/technology_cn2.png')})`}}>研发平台 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/main/technologyCn" style={{marginRight:'5px',marginLeft:'5px'}}>科创中心</NavLink>>
                  <NavLink to="/main/technologyCn" style={{marginLeft:'5px'}}>研发平台</NavLink>
                </section>
              </section>
              <hr/>
              <section className="technology_content">
                <section className="main_content clearfix">
                  <section className="main_left">
                    <li>
                      Abzed，基于多年对靶向和免疫治疗的深入研究和运用，建立拥有独立知识产<br/>
                    权，以Abzed 技术为核心的靶向免疫创新药物研发平台。平台能够开发针对癌细胞<br/>
                    表面蛋白靶点的生物药，研制的产品不仅能精准靶向识别癌细胞，同时还具有激活<br/>
                    人体抗癌免疫功能的特点，以获得更好的治疗效果。<br/>
                    </li>
                    <li>
                      平台技术核心是通过蛋白重组生物技术，所产生的蛋白具有三个功能结构域，<br />
                    既可以精准识别癌细胞，也可以通过与人体自身的抗体相结合，引导免疫系统对癌<br/>
                    细胞的攻击，同时还可以改变肿瘤微环境，增加免疫细胞的渗透能力。<br/>
                    </li>
                    <li>
                      我们将利用此平台创造出一系列癌症的靶向蛋白药物，可以对各种癌症进行相 <br/>
                      应的靶向免疫治疗，为亟需治疗的患者提供精准有效的抗癌药物。
                    </li>
                    <img alt="" src={require('../../assets/img/technology_cn/technology_cn4.png')}/>
                  </section>
                  <section className="main_right">
                    <img alt="" src={require('../../assets/img/technology_cn/technology_cn3.png')}/>
                    <span>Abzed 平台</span>
                  </section>
                </section>
              </section>
            </section>
        );
    }
}
