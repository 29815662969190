import React from 'react'
import './index.scss'
export default class HomeCn extends React.Component {

  routerLink(route) {
    this.props.history.push(route)
  }

  render() {
    return (
      <div className="home_cn">
        <section className="home_content">
          <section className="main_img" style={{backgroundImage: `url(${require('../../assets/img/home/main_1.png')})`}}>
            <article>
              <section>人类的健康，Abzed的事业</section>
            </article>
            <section className="learn_button">
              <img alt="" src={require('../../assets/img/home/more_cn.png')}/>
            </section>
          </section>
          <section className="content_text">
            Abzed 忠于企业使命，坚持科学创新，潜心研制新型靶向药物，为罹患癌症的患者服务。<br/>
            激活免疫抗癌机制，靶向癌症精准治疗。
          </section>
        </section>
        <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/home/banner2.png')})`}}>
          <section className="home_content">
            <section className="techno clearfix">
              <aside className="aside_img">
                <img alt="" src={require('../../assets/img/home/hechnology.png')}/>
              </aside>
              <article className="article_content">
                <section className="technology">
                  研发平台
                </section>
                <section className="technology_content">
                  独一无二的靶向免疫治疗平台
                </section>
                <section className="learn_btn clearfix" style={{backgroundImage: `url(${require('../../assets/img/home/more2.png')})`}} onClick={this.routerLink.bind(this, '/main/technologyCn')}>
                  <section className="learn_aside">更多了解</section>
                  <i className="learn_radius">&gt;</i>
                </section>
              </article>
            </section>
            <section className="techno clearfix" style={{marginLeft:'1.25rem'}}>
              <aside className="aside_img">
                <img alt="" src={require('../../assets/img/home/news.png')}/>
              </aside>
              <article className="article_content">
                <section className="technology">
                  合作共赢
                </section>
                <section className="technology_content">
                  共享、共进、共赢
                </section>
                <section className="learn_btn clearfix" style={{backgroundImage: `url(${require('../../assets/img/home/more2.png')})`}} onClick={this.routerLink.bind(this, '/main/newsCn')}>
                  <section className="learn_aside">更多了解</section>
                  <i className="learn_radius">&gt;</i>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="corporate_main">
          <section className="home_content">
            <section className="corporate_info">资讯在线</section>
            <section className="contact" style={{backgroundImage: `url(${require('../../assets/img/home/more5.png')})`}}>
              Contact
              <i className="i_radius">&gt;</i>
            </section>
          </section>
        </section>

      </div>
    );
  }
}
