import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class CompanyInfoCn extends React.Component {

  render() {
    return (
      <section className="company_info_cn">
        <section className="pipline_content">
          <img alt="" src={require('../../assets/img/company_info_cn/about.png')}/>
          <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/pipline/about.png')})`}}>关于Abzed <i>&gt;&gt;</i></section>
          <section className="brand">
            <NavLink to="/HomeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
            <NavLink to="/HomeCn" style={{marginRight:'5px',marginLeft:'5px'}}>关于Abzed</NavLink>>
            <NavLink to="/HomeCn/info" style={{marginLeft:'5px'}}>公司简介</NavLink>
          </section>
        </section>
        <hr/>
        <section className="pipline_content">
          <section className="main_content">
            <li>中国Abzed公司，是一家总部在中国的跨国药品研发企业，以自主知识产权创新药物研究、开发为核心的新型靶向抗肿瘤药物研发企业，始终聚焦于严重影
            响人类健康的肿瘤疾病。</li>
            <li>公司主要由海归博士团队和业内资深新药研发及管理团队组成，具有丰富的抗肿瘤创新药研发实践和管理经验。技术研发团队来自于美国宾夕法尼亚大学、
              中国科学院、中国药科院、浙江大学等国内外知名学府，并建立了功能齐全、技术先进、运行规范与国际接轨的新型药研技术平台。</li>
            <li>公司总部位于中国浙江省杭州市，目前已与浙江大学国际精准医学研究中心、浙江大学医学院附属邵逸夫医院达成深度合作，同时与当地政府开展战略合作，
              坚持围绕创新发展绿色发展高质量发展，共同聚焦影响人类健康肿瘤疾病的创新药研制，共同推进中国产学研高度结合的精准医学技术。</li>
            <li>Abzed始终坚守“以人为本”，秉承“创新驱动”的发展理念，成为创新药的领先者，为患者提供品质卓越、疗效确切、安全可靠的高性价比药物。</li>
            <li>Abzed潜心研制新型靶向抗肿瘤药物，终身致力于为全球患者带来希望！</li>
          </section>
        </section>
      </section>
    );
  }
}
