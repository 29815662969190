import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class CoreTeamCn extends React.Component {

    render() {
        return (
            <section className="core_team_cn">
              <section className="core_team_cn_content">
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/core_team_cn/core_team01.png')})`}}>
                  Abzed人才是我们强有力的竞争优势之一
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/technology_cn/technology_cn2.png')})`}}>核心团队 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>关于Abzed</NavLink>>
                  <NavLink to="/homeCn/coreteam" style={{marginLeft:'5px'}}>核心团队</NavLink></section>
              </section>
              <hr/>
              <section className="core_team_cn_content">
                <section className="main_content">
                  <section className="main_content_list clearfix">
                    <section className="main_content_left">
                      <img alt="" src={require('../../assets/img/core_team_cn/coreteam02.png')}/>
                    </section>
                    <section className="main_content_right">
                      <ul>
                        <li className="title"><span>Mark I.Greene</span> /教授</li>
                        <li>宾夕法尼亚大学病理学和实验室医学部的教授，是HER2抗体靶向治疗的奠基人，也是赫赛汀抗体药物的核心专利发明人之一。</li>
                        <hr/>
                        <li>1968年获得加拿大马尼托巴大学医学博士学位；</li>
                        <li>1976年成为加拿大皇家医学院的研究员；</li>
                        <li>2006年荣获国际医学AllynTaylor奖；</li>
                        <li>2007年荣获得亚当斯县乳腺癌研究奖和2008年的科茨洛夫奖。</li>
                        <li>现已发表350多篇专业论文及60多项专利申请。Greene教授的早期HER2抗体专利由宾夕法尼亚大学转让予 Genentech(Roche)制药</li>
                        <li>公司，在此专利基础上所研发的治疗乳腺癌和胃癌的药物赫赛汀和Perjeta年销售额超80亿美元，是全球医药史上名副其实的重量级</li>
                        <li>药物。</li>
                      </ul>
                    </section>
                  </section>
                  <section className="main_content_list clearfix">
                    <section className="main_content_left">
                      <img alt="" src={require('../../assets/img/core_team_cn/coreteam03.png')}/>
                    </section>
                    <section className="main_content_right">
                      <ul>
                        <li className="title"><span>张&nbsp;洪涛</span> /教授</li>
                        <li>1991年毕业于北京大学生物化学系，</li>
                        <li>1998年在宾夕法尼亚大学取得药学博士学位，</li>
                        <li>1998-2001年在宾大病理系完成博士后研究，后任职于宾夕法尼亚大学医学院病理及实验医药系。</li>
                        <li>1997-1999年成为美国癌症研究协会会员；</li>
                        <li>2005-2007年成为中美制药专业人士联合会大费城区会员；</li>
                        <li>2007年至今担任全球抗体协会会员。</li>
                        <li>目前担任《当代信号传导与治疗》杂志编委及多家国际期刊杂志特邀审评人。</li>
                        <li>主要研究方向为癌症的靶向药物治疗、重组蛋白药物对癌症的靶向免疫治疗以及巨噬细胞在免疫和肿瘤中的功能。</li>
                        <li>在Nature Medicine等国际权威科学杂志上发表过70多篇论文，是17个已批准专利和一系列待批专利的发明人。</li>
                      </ul>
                    </section>
                  </section>
                  <section className="main_content_list clearfix main_content_self_list">
                    <section className="main_content_left">
                      <img alt="" src={require('../../assets/img/core_team_cn/coreteam04.png')}/>
                    </section>
                    <section className="main_content_right">
                      <ul>
                        <li className="title"><span>朱&nbsp;志强</span> /博士</li>
                        <li>2001年毕业于中国科学技术大学生命科学学院生物系，获生物学学士学位。</li>
                        <li>而后继续在中国科学技术大学攻读博士，于2009年获得博士学位。</li>
                        <li>2015年至今，在宾夕法尼亚大学医学院担任助理研究员的职务，引领设计了Z结构域的人源化结构平台，并且进一步成功设计了人</li>
                        <li>源化的 Grababody抗体类似物。在J.Biol.Chem.，CancerRes.，Nat.Commun.等杂志上共发表论文二十几篇，并且获得了两项科研</li>
                        <li>专利。</li>
                      </ul>
                    </section>
                  </section>
                  <section className="main_content_more">
                    <a href="http://www.baidu.com">更多>></a>
                  </section>
                </section>
              </section>
            </section>
        );
    }
}
