import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import './App.scss';
import 'antd/dist/antd.css'


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: window.localStorage.getItem('state') ? window.localStorage.getItem('state') === 'true' : true
    }
    this.toRoutehandle = this.toRoutehandle.bind(this)
    this.switchCnOrEn = this.switchCnOrEn.bind(this)
  }
  componentWillMount() {
    let storage = window.localStorage
    storage.setItem('state',this.state.show)
  }
  // 路由默认跳转到新闻页
  toRoutehandle () {
    if (this.state.show) {
      this.props.history.push('/main/news')
    } else {
      this.props.history.push('/main/newsCn')
    }
  }
  switchCnOrEn () {
    if (this.state.show) {
      this.props.history.push('/homeCn')
    } else {
      this.props.history.push('/')
    }
    this.setState(() => ({
      show: !this.state.show
    }),()=>{
      let storage = window.localStorage
      storage.setItem('state',this.state.show)
    })
  }

  render() {
    return (
      <div className="App">
        {
          this.state.show ?
          <section> {/*英文*/}
            <section className="topHeader">
              <section className="topHeader_content clearfix">
                <section className="search">
                  <input className="search_input" type="text" placeholder="Type your search"/>
                  <button className="search_btn" style={{backgroundImage: `url(${require('./assets/img/nav/search.png')})`}} onClick={this.toRoutehandle}></button>
                </section>
              </section>
            </section>
            <header className="header">
              <div className="header_content clearfix">
                <h1 style={{marginRight:'1rem'}}><NavLink to="/"><img alt="" src={require('./assets/img/nav/logo.png')}/></NavLink></h1>
                <nav className="nav">
                  <ul className="nav_ul">
                    <li>
                      <NavLink to="/">OUR COMPANY</NavLink>
                    </li>
                    <li>
                      <NavLink to="/main/technology">TECHNOLOGY</NavLink>
                    </li>
                    <li>
                      <NavLink to="/main/pipline">PIPELINE</NavLink>
                    </li>
                    <li>
                      <NavLink to="/main/news">NEWS</NavLink>
                    </li>
                    <li className="pull_over">
                      <NavLink to="/main/contact">CONTACT</NavLink>
                      <ul className="pull_over_ul">
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/main/contact">CONTACT</NavLink></li>
                        <li><NavLink to="/main/contact/from">FROM</NavLink></li>
                      </ul>
                    </li>
                    <span className="switch_cn" onClick={this.switchCnOrEn}>
                      <img alt="" src={require('./assets/img/nav/cn_switch.png')}/>
                      <span style={{cursor:'pointer'}} >{this.state.show ? '中文':'English'}</span>
                    </span>
                  </ul>
                </nav>
              </div>
            </header>
          </section>
            :
          <section> {/*中文*/}
            <section className="topHeader">
              <section className="topHeader_content clearfix">
                <section className="search">
                  <input className="search_input" type="text" placeholder="输入您的搜索"/>
                  <button className="search_btn" style={{backgroundImage: `url(${require('./assets/img/nav/search.png')})`}} onClick={this.toRoutehandle}></button>
                </section>
              </section>
            </section>
            <header className="header">
              <div className="header_content clearfix">
                <h1 style={{marginRight:'1rem'}}><NavLink to="/"><img alt="" src={require('./assets/img/nav/logo.png')}/></NavLink></h1>
                <nav className="nav">
                  <ul className="nav_ul">
                    <li className="pull_over">
                      <NavLink to="/homeCn">关于Abzed</NavLink>
                      <ul className="pull_over_ul">
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/homeCn">首页</NavLink></li>
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/homeCn/info">公司简介</NavLink></li>
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/homeCn/coreteam">核心团队</NavLink></li>
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/homeCn/companyculture">企业文化</NavLink></li>
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/homeCn/talentsIn">人才招聘</NavLink></li>
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/homeCn/socialrespon">社会责任</NavLink></li>
                        <li><NavLink to="/homeCn/teamwork">战略合作</NavLink></li>
                      </ul>
                    </li>
                    <li className="pull_over">
                      <NavLink to="/main/technologyCn">科创中心</NavLink>
                      <ul className="pull_over_ul">
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/main/technologyCn">研发平台</NavLink></li>
                        <li><NavLink to="/homeCn/reserch_products">研发产品</NavLink></li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to="/main/newsCn">资讯在线</NavLink>
                    </li>
                    <li className="pull_over">
                      <NavLink to="/main/contactCn">联系我们</NavLink>
                      <ul className="pull_over_ul">
                        <li style={{borderBottom:'1px solid #f2f2f2'}}><NavLink to="/main/contactCn">联系我们</NavLink></li>
                        <li><NavLink to="/main/contact/fromCn">信息反馈</NavLink></li>
                      </ul>
                    </li>
                    <span className="switch_cn" onClick={this.switchCnOrEn}>
                      <img alt="" src={require('./assets/img/nav/cn_switch.png')}/>
                      <span style={{cursor:'pointer'}}>{this.state.show ? '中文':'English'}</span>
                    </span>
                  </ul>
                </nav>
              </div>
            </header>
          </section>
        }
        {this.props.children}
        {
          this.state.show ?
          <footer className="footer"> {/*英文*/}
            <section className="footer_content">
              <section className="footer_left">AbZed Pharma Corp 2019</section>
              <nav className="footer_nav">
                <span><NavLink to="/"></NavLink></span>
                <span><NavLink to="/main/technology">TECHNOLOGY</NavLink></span>
                <span><NavLink to="/main/pipline">Pipeline</NavLink></span>
                <span><NavLink to="/main/news">NEWS</NavLink></span>
                <span><NavLink to="/main/contact">CONTACT</NavLink></span>
              </nav>
            </section>
          </footer>
            :
          <footer className="footer"> {/*中文*/}
            <section className="footer_content">
              <section className="footer_left">2019-2026 AblynxDisclaimerSitemapCookies</section>
              <nav className="footer_nav">
                <span><NavLink to="/homeCn">关于Abzed</NavLink></span>
                <span><NavLink to="/main/technologyCn">科创中心</NavLink></span>
                <span><NavLink to="/main/newsCn">资讯在线</NavLink></span>
                <span><NavLink to="/main/contactCn">联系我们</NavLink></span>
              </nav>
            </section>
          </footer>
        }
      </div>
    );
  }
}

export default withRouter(App);
