import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class SocialResponCn extends React.Component {

    render() {
        return (
            <section className="social_respon_cn">
              <section className="social_respon_cn_content">
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/social_respon_cn/social_respon_cn1.png')})`}}>
                  积极践行社会责任，坚持助力健康公益
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/social_respon_cn/social_respon_cn2.png')})`}}>社会责任 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>关于Abzed</NavLink>>
                  <NavLink to="/homeCn/socialrespon" style={{marginLeft:'5px'}}>社会责任</NavLink></section>
              </section>
              <hr/>
              <section className="social_respon_cn_content">
                <section className="main_content">
                  <article>
                    Abzed在发展企业的同时，始终谨记社会责任感，积极参加各项社会公益事业，将社会责任和企业发展紧密结合在一起，致力于构建和谐的行业生态圈，用实际行动使公司成为一个负责任的企业公民。
                  </article>
                  <img alt="" src={require('../../assets/img/social_respon_cn/social_respon_cn4.png')}/>
                  <img style={{margin:'0 0.28rem'}} alt="" src={require('../../assets/img/social_respon_cn/social_respon_cn3.png')}/>
                  <img alt="" src={require('../../assets/img/social_respon_cn/social_respon_cn5.png')}/>
                </section>
              </section>
            </section>
        );
    }
}
