import React from 'react'
import './index.scss'
import {NavLink} from 'react-router-dom'
export default class NewsCn extends React.Component {

	render() {
		return(
			<div className="news_cn">
          <section  className="contact_content">
             <aside className="aside_img">
                 <img alt="" src={require('../../assets/img/news/new1.png')}/>
             </aside>
             <section className="bg-nex" style={{backgroundImage: `url(${require('../../assets/img/contact/lianxi.png')})`}}>
                 <section className="bg-nex-word"> 咨询在线 <i>&gt;&gt;</i></section>
             </section>
             <section className="abz-word">
               <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
               <NavLink to="/main/newsCn" style={{marginRight:'5px',marginLeft:'5px'}}>咨询在线</NavLink>
             </section>
          </section>
           <hr/>
          <section className="height1"></section>
          <section  className="touch_content">
            <section className="touch-lef">
              <section className="new-word">
                <p className="new-word1">科研论文</p>
                <p className="new-word2">更多 >></p>
              </section>
              <ul>
                <li>1.Cai Z, Fu T, Nagai Y, Lam L, Yee M, Zhu Z, Zhang H. scFv-Based</li>
                <li>&nbsp;&nbsp;&nbsp;"Grababody" as a General Strategy to Improve Recruitment of </li>
                <li>&nbsp;&nbsp;&nbsp;Immune Effector Cells to Antibody-Targeted Tumors. Cancer Res. </li>
                <li>&nbsp;&nbsp;&nbsp;2013;73(8):2619-27. Epub 2013/02/12. </li>
                <li>&nbsp;&nbsp;&nbsp;doi: 10.1158/0008-5472.CAN-12-3920. </li>
                <li>&nbsp;&nbsp;&nbsp;<a href="http://baidu.com">http://cancerres.aacrjournals.org/content/73/8/2619.short</a></li>
              </ul>
              <ul>
                <li>2. Zhang H. Empowering scFv with effector cell functions for </li>
                <li>&nbsp;&nbsp;&nbsp;improved anticancer therapeutics. Oncoimmunology.</li>
                <li>&nbsp;&nbsp;&nbsp;2013;2(6):e24439.</li>
                <li>&nbsp;&nbsp;&nbsp;<a href="http://baidu.com">https://www.tandfonline.com/doi/abs/10.4161/onci.24439</a></li>
              </ul>
              <ul>
                <li>3. Nagai Y, Tsuchiya H, Runkle EA, Young PD, Ji MQ, Norton L, </li>
                <li>&nbsp;&nbsp;&nbsp;Drebin JA, Zhang H, Greene MI. Disabling of the erbB Pathway</li>
                <li>&nbsp;&nbsp;&nbsp;Followed by IFN-gamma Modifies Phenotype and Enhances</li>
                <li>&nbsp;&nbsp;&nbsp;Genotoxic Eradication of Breast Tumors. Cell Rep. </li>
                <li>&nbsp;&nbsp;&nbsp;2015;12(12):2049-59. Epub 2015/09/15. </li>
                <li>&nbsp;&nbsp;&nbsp;doi: 10.1016/j.celrep.2015.08.044. </li>
                <li>&nbsp;&nbsp;&nbsp;<a href="http://baidu.com">https://www.sciencedirect.com/science/article/pii/S2211124715009298</a></li>
              </ul>
              <ul>
                <li>4. Zhang H, Lam L, Nagai Y, Zhu Z, Chen X, Ji MQ, Greene MI. A </li>
                <li>&nbsp;&nbsp;&nbsp;targeted immunotherapy approach for HER2/neu transformed</li>
                <li>&nbsp;&nbsp;&nbsp;tumors by coupling an engineered effector domain with </li>
                <li>&nbsp;&nbsp;&nbsp;interferon-gamma. Oncoimmunology. 2018;7(4):e1300739.</li>
                <li>&nbsp;&nbsp;&nbsp;doi: 10.1080/2162402X.2017.1300739.</li>
                <li>&nbsp;&nbsp;&nbsp;<a href="http://baidu.com">https://www.tandfonline.com/doi/abs/10.1080/2162402X.2017.1300739</a></li>
              </ul>
            </section>
            <section className="touch-rig">
             <section className="new-word">
                <p className="new-word1">行业新闻</p>
                <p className="new-word2">更多 >></p>
              </section>
              <ul>
                <li>关于Her2的罗氏新闻</li>
                <li>关于EGFR的BMS新闻</li>
                <li>关于EGFR的Amgen新闻</li>
              </ul>
            </section>
          </section>
      </div>
    );
	}
}
