import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class SocialResponCn extends React.Component {

    render() {
        return (
            <section className="reserch_products_cn">
              <section className="reserch_products_cn_content">
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/reserch_products_cn/reserch_products_cn01.png')})`}}>
                  Abzed<br/>
                  为亟需治疗的患者提供精准有效的创新药物
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/reserch_products_cn/reserch_products_cn02.png')})`}}>研发产品 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/main/technologyCn" style={{marginRight:'5px',marginLeft:'5px'}}>科创中心</NavLink>>
                  <NavLink to="/homeCn/reserch_products" style={{marginLeft:'5px'}}>研发产品</NavLink></section>
              </section>
              <hr/>
              <section className="reserch_products_cn_content">
                <section className="main_content">
                  <section className="main_li" style={{marginBottom:'0.47rem'}}>
                    <h2>Her2Abzed</h2>
                    <article>
                      <span className="article_title">【核心概述】</span>  Her2Abzed是根据AbZED技术打造的靶向治疗HER2癌症的产品，是新一代的乳腺癌、胃癌靶向治疗药物，目前在动物实验中显示治疗效果超
                      越HER2抗体药物及其他生物类似药，并且对耐药性的癌细胞治愈也有明显的疗效。
                    </article>
                  </section>
                  <section className="main_li">
                    <h2>EGFRAbzed</h2>
                    <article>
                      <span className="article_title">【作用机制】</span>  EGFR AbZED 是一种靶向肺癌或者肠癌表面 EGFR 的蛋白药物， 利用人源化的 ZED 结构域，可以结合人体内源性抗体 Fc 段，桥接 NK 细胞
                      至靶细胞，产生癌细胞杀伤效应；同时，蛋白中的 IFN-结构能够改变肿瘤周围的微环境，增加淋巴细胞和 M1 型肿瘤相关性巨噬细胞（TAM- M1）—— 一
                      种对癌细胞有杀伤作用的巨噬细胞，将免疫“冷肿瘤”转变为“热肿瘤”，协同增强免疫抗癌效果。与免疫检查点抑制剂和CAR T细胞治疗一样， EGFR AbZED
                      也属于肿瘤免疫治疗，是一种创新的免疫靶向治疗蛋白药。
                    </article>
                  </section>
                </section>
              </section>
            </section>
        );
    }
}
