import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class Technology extends React.Component {

    render() {
        return (
            <section className="technology">
              <section className="technology_content">
                <img alt="" src={require('../../assets/img/technology/technogy1.png')}/>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/technology/technogy2.png')})`}}>OVERVIER <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/" style={{marginRight:'5px',marginLeft:'5px'}}>Abzed</NavLink>>
                  <NavLink to="/main/technology" style={{marginRight:'5px',marginLeft:'5px'}}>Technology</NavLink>>
                  <NavLink to="/main/technology" style={{marginLeft:'5px'}}>Understanding AbZED molecules</NavLink></section>
              </section>
              <hr/>
              <section className="technology_content">
                <section className="main_content clearfix">
                  <section className="main_left">
                    AbZED develops the next generation of antibody-like<br/>
                    proteins with immune therapy functions.<br/>
                    The ZED domian utilizes endogenous lgG to<br/>
                    mediate effector cell functions.<br/>
                    IFNγ creates a tumor microenvironment friendly to infiltrating
                    immune cells.
                    <img alt="" src={require('../../assets/img/technology/technogy3.png')}/>
                  </section>
                  <section className="main_right">
                    <img alt="" src={require('../../assets/img/technology/technogy4.png')}/>
                  </section>
                </section>
              </section>
            </section>
        );
    }
}
