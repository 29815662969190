import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class CompanyCulture extends React.Component {

    render() {
        return (
            <section className="company_culture">
              <section className="core_team_cn_content">
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/company_culture_cn/companyculture01.png')})`}}>
                  精准化研究，精细化管理，精益化服务，精确化发展
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/technology_cn/technology_cn2.png')})`}}>企业文化 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>关于Abzed</NavLink>>
                  <NavLink to="/homeCn/companyculture" style={{marginLeft:'5px'}}>企业文化</NavLink>
                </section>
              </section>
              <hr/>
              <section className="core_team_cn_content">
                <section className="main_content">
                  <article className="main_content_title">
                    <span>员工风采（我与ABZ）/</span>个人故事（优秀核心人员到基层）、员工期刊、荣誉
                  </article>
                  <section className="main_img"><img alt="" src={require('../../assets/img/company_culture_cn/companyculture02.png')} /></section>
                  <article className="article_content">
                    Abzed结合自身实际，继承并发扬优秀传统，树立了“精准化研究，精细化管理，精益化服务，精确化发展”的核心价值观，营造了富有企业特色和时代特色的企业文化。
                  </article>
                  <section className="main_content_img">
                    <img alt="" src={require('../../assets/img/company_culture_cn/companyculture03.png')} />
                    <img className="list_img" alt="" src={require('../../assets/img/company_culture_cn/companyculture04.png')} />
                    <section className="list_left">
                      <ul>
                        <li style={{fontSize:'22px'}}>企业大事记</li>
                        <li style={{fontSize:'18px'}}>内外训、团建、年会、节假日活动等。</li>
                      </ul>
                    </section>
                  </section>
                </section>
              </section>
            </section>
        );
    }
}
