import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
import { Select } from 'antd';
import { Input } from 'antd';
export default class TalentsInCn extends React.Component {

    render() {
        const Option = Select.Option;
        return (
            <section className="talents_in_cn">
              <section className="talents_in_cn_content">
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/talentsin_cn/talentsin_cn.png')})`}}>
                  <ul>
                    <li>深度：钻研了无止境，专业并非终点</li>
                    <li>广度：拓展多样视野，全面综合发展</li>
                    <li>宽度：不忘目标使命，不弃品德本性</li>
                    <li>长度：结合企业发展，打造持久规划</li>
                  </ul>
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/technology_cn/technology_cn2.png')})`}}>人才招聘 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>关于Abzed</NavLink>>
                  <NavLink to="/homeCn/talentsIn" style={{marginLeft:'5px'}}>人才招聘</NavLink></section>
              </section>
              <hr/>
              <section className="talents_in_cn_content">
                <section className="main_content">
                  <section className="main_top">
                    <ul>
                      <li style={{fontSize:'22px',lineHeight:'59px'}}><span style={{color:'#2158a4'}}>Abzed</span> 中国诚挚邀请有志英才加入</li>
                      <li>我们注重培养专业人才，营造多元化、包容性的环境。</li>
                      <li>中美联合培育发展，通力合作，竞争共赢。</li>
                      <li>在Abzed的价值观引领下坚持推进科技创新，造福社会，为更多患者带来希望。</li>
                    </ul>
                  </section>
                </section>
              </section>
              <hr/>
              <section className="talents_in_cn_content">
                <section className="main_content" style={{marginTop:'20px'}}>
                  <section className="main_first">
                    <dl>
                      <dt>① 中外联合培育模式</dt>
                      <dd>Abzed的学习与发展以混合式培训为主要方式，</dd>
                      <dd>从新进员工到专业人士，再到中高层管理人员，设计了完善的学习与发展计划，涵盖：</dd>
                      <dd>专业技术类培训/人员管理类培训/个人有效性的培训/国际发展项目/国内发展项目/主管核心辅导</dd>
                    </dl>
                    <section className="main_study clearfix">
                      <section className="main_study_left">70%</section>
                      <section className="main_study_right">
                        <p style={{margin: '12px 0 0 29px'}}>
                          <span style={{fontSize:'23px'}}>实践式学习</span><br/>
                          各种基于工作岗位的、富有挑战性的、能够扩展职责和能力的工作任务。
                        </p>
                      </section>
                    </section>
                    <section className="main_study clearfix">
                      <section className="main_study_left" style={{height:'100px',backgroundColor:'#90abd1'}}>20%</section>
                      <section className="main_study_right" style={{height:'100px',backgroundColor:'#90abd1'}}>
                        <p style={{margin: '12px 0 0 29px'}}>
                          <span style={{fontSize:'23px'}}>互动式学习</span><br/>
                          教学双方平等交流探讨的过程中，达到不同观点碰撞交融，<br/>
                          进而激发教学双方的主动性和探索性 ，大橙提高教学效果。<br/>
                        </p>
                      </section>
                    </section>
                    <section className="main_study clearfix">
                      <section className="main_study_left" style={{color:'#2158a4',backgroundColor:'#bccde4'}}>10%</section>
                      <section className="main_study_right" style={{color:'#2158a4',backgroundColor:'#bccde4'}}>
                        <p style={{margin: '12px 0 0 29px'}}>
                          <span style={{fontSize:'23px'}}>培训方式</span><br/>
                          线下课堂培训，在线培训学习，内外部经验分享。
                        </p>
                      </section>
                    </section>
                  </section>
                  <section className="main_second">
                    <dl>
                      <dt>②招聘模式</dt>
                      <dd>Abzed，一直在寻找富有钻研、创新、合作的人才和领导者。</dd>
                    </dl>
                    <section className="main_study">
                      <section className="main_study_filter">
                        <Select defaultValue="中国" style={{ width: '117px',color:'#a0a0a0' }}>
                          <Option value="china">中国</Option>
                          <Option value="">美国</Option>
                        </Select>
                        <Select defaultValue="高管岗位" style={{ width: '117px',color:'#a0a0a0' }}>
                          <Option value="china">高管岗位</Option>
                          <Option value="">普通岗位</Option>
                        </Select>
                        <Select defaultValue="地区" style={{ width: '117px',color:'#a0a0a0' }}>
                          <Option value="china">地区</Option>
                          <Option value="">美国</Option>
                        </Select>
                        <Input placeholder="核心管理" style={{width: '117px'}} />
                        <Select defaultValue="行政管理" style={{ width: '117px',color:'#a0a0a0' }}>
                          <Option value="china">行政管理</Option>
                          <Option value="">程序员</Option>
                        </Select>
                        <Input placeholder="执行岗位" style={{width: '117px'}}/>
                        <Input placeholder="基层岗位" style={{width: '117px'}}/>
                        <Input placeholder="" style={{width: '117px'}}/>
                      </section>
                      <section className="main_study_content">
                        <ul>
                          <li>质量管理员 [ 天津-滨海新区 ]</li>
                          <li>财务会计核算 [ 天津 ]</li>
                        </ul>
                        <ul>
                          <li>药店营业员 [ 天津-南开区 ]</li>
                          <li>连锁运营终端 [ 贵阳 ]</li>
                        </ul>
                        <ul>
                          <li>质量管理员 [ 天津-滨海新区 ]</li>
                          <li>财务会计核算 [ 天津 ]</li>
                        </ul>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
        );
    }
}
