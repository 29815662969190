import React from 'react'
import './index.scss'
export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  routerLink(route) {
    this.props.history.push(route)
  }

  render() {
    return (
      <div className="home">
        <section className="home_content">
          <section className="main_img" style={{backgroundImage: `url(${require('../../assets/img/home/main_1.png')})`}}>
            <article>
              <section>ABZED ————</section>
              <section>ADDING</section>
              <section>IMMUNE RESPONSE</section>
              <section>TO TARGETED CANCER</section>
              <section>THERAPIES.</section>
            </article>
            <section className="learn_button">
              <img alt="" src={require('../../assets/img/home/more.png')}/>
            </section>
            <p>ACTIVATE AUTOIMMUNITY, TARGETED IMMUNOTHERAPY.</p>
          </section>
          <section className="content_text">
            AbZed is dedicated to turning hope into medicines to benefit the society.
          </section>
        </section>
        <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/home/banner2.png')})`}}>
          <section className="home_content">
            <section className="techno clearfix">
              <aside className="aside_img">
                <img alt="" src={require('../../assets/img/home/hechnology.png')}/>
              </aside>
              <article className="article_content">
                <section className="technology">
                  TECHNOLOGY
                </section>
                <section className="technology_content">
                  A marriage between target therapy
                  <br/>
                  and immunotherapy
                </section>
                <section className="learn_btn clearfix" style={{backgroundImage: `url(${require('../../assets/img/home/more2.png')})`}} onClick={this.routerLink.bind(this, '/main/technology')}>
                  <section className="learn_aside">LEARN MORE</section>
                  <i className="learn_radius">&gt;</i>
                </section>
              </article>
            </section>
            <section className="techno clearfix" style={{marginLeft:'1.25rem'}}>
              <aside className="aside_img">
                <img alt="" src={require('../../assets/img/home/news.png')}/>
              </aside>
              <article className="article_content">
                <section className="technology">
                  NEWS
                </section>
                <section className="technology_content">
                  Publications and Presentations
                </section>
                <section className="learn_btn clearfix" style={{backgroundImage: `url(${require('../../assets/img/home/more2.png')})`}} onClick={this.routerLink.bind(this, '/main/news')}>
                  <section className="learn_aside">LEARN MORE</section>
                  <i className="learn_radius">&gt;</i>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="corporate_main">
          <section className="home_content">
            <section className="corporate_info">CORPORATE INFORMATION</section>
            <section className="contact" style={{backgroundImage: `url(${require('../../assets/img/home/more5.png')})`}}>
              Contact
              <i className="i_radius">&gt;</i>
            </section>
          </section>
        </section>

      </div>
    );
  }
}
