import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'

export default class ContactCn extends React.Component {

	render() {
		return(
			<div className="contact_cn">
        <section  className="contact_content">
           <aside className="aside_img">
               <img alt="" src={require('../../assets/img/contact/banner.png')}/>
           </aside>
           <section className="bg-nex" style={{backgroundImage: `url(${require('../../assets/img/contact/lianxi.png')})`}}>
               <section className="bg-nex-word"> 联系我们 <i>&gt;&gt;</i></section>
           </section>
           <section className="abz-word">
             <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
             <NavLink to="/main/contactCn" style={{marginLeft:'5px'}}>联系我们</NavLink>
           </section>
        </section>
        <hr/>
        <section  className="touch_content">
            <section className="touch-lef">
                <ul>
                  <li> 中国公司 </li>
                  <li> 杭州，浙江，310000 </li>
                  <li> 中国 </li>
                </ul>
            </section>
            <section className="touch-rig">
              <ul>
                  <li> 美国公司 </li>
                  <li> 费城，宾西法利亚 19428 </li>
                  <li> 美国 </li>
                </ul>
            </section>
        </section>
        <section className="tel_content">
          <section className="tel">
            <ul>
                <li> 电话： </li>
                <li> 传真：  </li>
                <li> 邮箱： </li>
            </ul>
          </section>
        </section>
      </div>
    );
	}
}
