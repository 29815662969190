import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class TeamWorkCn extends React.Component {

    render() {
        return (
            <section className="team_work_cn">
              <section className="team_work_cn_content">
                <section className="bg_main" style={{backgroundImage: `url(${require('../../assets/img/team_work_cn/team_work_cn01.png')})`}}>
                  立足浙江，引领国内，走向世界！
                </section>
                <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/team_work_cn/team_work_cn02.png')})`}}>战略合作 <i>&gt;&gt;</i></section>
                <section className="brand">
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
                  <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>关于Abzed</NavLink>>
                  <NavLink to="/homeCn/teamwork" style={{marginLeft:'5px'}}>战略合作</NavLink>
                </section>
              </section>
              <hr/>
              <section className="team_work_cn_content">
                <section className="main_content">
                  <article className="article_content">
                    Abzed 拥有世界权威医药技术、汇聚全球顶尖医学专家，与浙江大学国际精准医学研究中心、浙江大学医学院附属邵逸夫医院达成深度战略合作，全心全意
                    致力于精准医学相关的技术研究、临床应用以及相关的培训推广和示范；聚焦整合国内外精准医学相关资源，形成全国精准医学顶尖的技术示范基地；聚集海外
                    高层次人才和本地企业、医疗机构，形成国内产学研高度结合的精准医学联盟；打造世界一流的精准医学中心。
                  </article>
                </section>
              </section>
              <hr/>
              <section className="team_work_cn_content">
                <section className="team_work_cn_bottom">
                  <h2 className="team_work_cn_title">合作伙伴</h2>
                  <img style={{width:'100%'}} alt="" src={require('../../assets/img/team_work_cn/team_work_cn03.png')}/>
                </section>
              </section>
            </section>
        );
    }
}
