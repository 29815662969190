import React from 'react'
import { HashRouter as Router, Route, Switch} from 'react-router-dom'
import App from './App'
import Home from './components/Home'
import HomeCn from './components/HomeCn'
import CompanyInfoCn from './components/CompanyInfoCn'
import CoreTeamCn from './components/CoreTeamCn'
import CompanyCulture from './components/CompanyCultureCn'
import TalentsIn from './components/TalentsInCn'
import SocialResponCn from './components/SocialResponCn'
import Contact from './components/Contact'
import ContactCn from './components/ContactCn'
import TeamWorkCn from './components/TeamWorkCn'
import News from './components/News'
import NewsCn from './components/NewsCn'
import ReserchProductsCn from './components/ReserchProductsCn'
import Pipline from './components/Pipline'
import Technology from './components/Technology'
import TechnologyCn from './components/TechnologyCn'
import ContactFrom from './components/ContactForm'
import ContactFromCn from './components/ContactFormCn'
// import NoMatch from './NoMatch'
export default class IRouter extends React.Component{

  render(){
    return (
      <Router>
        <App>
          <Switch>
            <Route path="/" render={()=>
                <Switch>
                  <Route exact={true} path="/" component={Home}></Route>
                  <Route exact={true} path="/homeCn" component={HomeCn}></Route>
                  <Route path="/homeCn/info" component={CompanyInfoCn}></Route>
                  <Route path="/homeCn/coreteam" component={CoreTeamCn}></Route>
                  <Route path="/homeCn/companyculture" component={CompanyCulture}></Route>
                  <Route path="/homeCn/talentsIn" component={TalentsIn}></Route>
                  <Route path="/homeCn/socialrespon" component={SocialResponCn}></Route>
                  <Route path="/homeCn/teamwork" component={TeamWorkCn}></Route>
                  <Route path="/main/technology" component={Technology}></Route>
                  <Route path="/homeCn/reserch_products" component={ReserchProductsCn}></Route>
                  <Route path="/main/pipline" component={Pipline}></Route>
                  <Route path="/main/news" component={News}></Route>
                  <Route exact path="/main/contact" component={Contact}></Route>
                  <Route path="/main/contact/from" component={ContactFrom}></Route>
                  <Route path="/main/technologyCn" component={TechnologyCn}></Route>
                  <Route path="/main/newsCn" component={NewsCn}></Route>
                  <Route exact path="/main/contactCn" component={ContactCn}></Route>
                  <Route path="/main/contact/fromCn" component={ContactFromCn}></Route>
                </Switch>
            }></Route>
          </Switch>
        </App>
      </Router>
    );
  }
}
