import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { Input, message } from 'antd'
import {url} from "../../utils";

export default class ContactFromCn extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      contactName: '',
      contactEmail: '',
      contactSubject: '',
      contactMessage: ''
    }
  }
  sendBtn () {
    if (this.state.contactName === ''){
      message.error('您的名字不能为空');
    } else if (this.state.contactEmail === '') {
      message.error('您的邮箱不能为空');
    }else {
      axios({
        method:'post',
        url:`${url}/insertcontactuser`,
        data: {
          contactName: this.state.contactName,
          contactEmail: this.state.contactEmail,
          contactSubject: this.state.contactSubject,
          contactMessage: this.state.contactMessage
        }
      }).then(({data}) => {
        if (data.code === 0) {
          message.success('提交成功');
          setTimeout(()=>{
            this.setState({
              contactName: '',
              contactEmail: '',
              contactSubject: '',
              contactMessage: ''
            })
          },2000)
        }
      })
    }
  }
  inputName (e) {
    this.setState({
      contactName: e.target.value
    })
  }
  inputEmail (e) {
    this.setState({
      contactEmail: e.target.value
    })
  }
  inputSubject(e) {
    this.setState({
      contactSubject: e.target.value
    })
  }
  inputMessage (e) {
    this.setState({
      contactMessage: e.target.value
    })
  }
	render() {
		return(
			<div className="contact_form_cn">
        <section  className="contact_content">
           <aside className="aside_img">
               <img alt="" src={require('../../assets/img/contact/banner.png')}/>
           </aside>
           <section className="bg-nex" style={{backgroundImage: `url(${require('../../assets/img/contact/lianxi.png')})`}}>
               <section className="bg-nex-word"> 信息反馈 <i>&gt;&gt;</i></section>
           </section>
           <section className="abz-word">
             <NavLink to="/homeCn" style={{marginRight:'5px',marginLeft:'5px'}}>首页</NavLink>>
             <NavLink to="/main/contactCn" style={{marginRight:'5px',marginLeft:'5px'}}>联系我们</NavLink>>
             <NavLink to="/main/contact/fromCn" style={{marginLeft:'5px'}}>信息反馈</NavLink>
           </section>
        </section>
        <hr/>
        <section  className="touch_content">
          <section style={{marginTop:'0.17rem'}}><span>您的名字(必填)</span><Input onChange={this.inputName.bind(this)} value={this.state.contactName} type="text"/></section>
          <section><span>您的邮箱(必填)</span><Input onChange={this.inputEmail.bind(this)} value={this.state.contactEmail} type="text"/></section>
          <section><span>主题</span><Input onChange={this.inputSubject.bind(this)} value={this.state.contactSubject} type="text"/></section>
          <section><span style={{height:'74px',lineHeight:'74px'}}>您的具体意见</span><Input onChange={this.inputMessage.bind(this)} value={this.state.contactMessage} type="textarea" style={{height:'74px'}}/></section>
          <section>
            <button onClick={this.sendBtn.bind(this)}>提交</button>
          </section>
        </section>
      </div>
    );
	}
}
