import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'

export default class Contact extends React.Component {

	render() {
		return(
			<div className="contact">
        <section  className="contact_content">
           <aside className="aside_img">
               <img alt="" src={require('../../assets/img/contact/banner.png')}/>
           </aside>
           <section className="bg-nex" style={{backgroundImage: `url(${require('../../assets/img/contact/lianxi.png')})`}}>
               <section className="bg-nex-word"> Contact from <i>&gt;&gt;</i></section>
           </section>
           <section className="abz-word">
             <NavLink to="/" style={{marginRight:'5px',marginLeft:'5px'}}>Abzed</NavLink>>
             <NavLink to="/main/contact" style={{marginLeft:'5px'}}>Contact</NavLink>
           </section>
        </section>
        <hr/>
        <section  className="touch_content">
            <section className="touch-lef">
                <ul>
                  <li> AbZed,INC </li>
                  <li> Xxxxxxx  </li>
                  <li> Hang zhou,Zhejiang </li>
                  <li> China </li>
                </ul>
            </section>
            <section className="touch-rig">
              <ul>
                  <li> AbZed,INC </li>
                  <li> Xxxxxxx  </li>
                  <li> Phladephia,PA 19428 </li>
                  <li> USA </li>
                </ul>
            </section>
        </section>
        <section className="tel_content">
          <section className="tel">
            <ul>
                <li> Tel </li>
                <li> Fac  </li>
                <li> info.us@abzedpharm.com </li>
            </ul>
          </section>
        </section>
      </div>
    );
	}
}
