import React from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'
export default class Pipline extends React.Component {

  render() {
    return (
      <section className="pipline">
        <section className="pipline_content">
          <img alt="" src={require('../../assets/img/pipline/banner.png')}/>
          <section className="bg_overreview" style={{backgroundImage: `url(${require('../../assets/img/pipline/about.png')})`}}>OVERVIER <i>&gt;&gt;</i></section>
          <section className="brand">
            <NavLink to="/" style={{marginRight:'5px',marginLeft:'5px'}}>Abzed</NavLink>>
            <NavLink to="/main/pipline" style={{marginRight:'5px',marginLeft:'5px'}}>Pipeline</NavLink>>
            <NavLink to="/main/pipline" style={{marginLeft:'5px'}}>Overview</NavLink>
          </section>
        </section>
        <hr/>
        <section className="pipline_content">
          <section className="main_content">
          Abzed is a new fusion protein platform based biopharmaceutical comany that is devoted to creating innovative medicines to bring benefit to our society.
          The first two fusion protein products has finished animal studies and is preparing for investigational New Drug(IND)enabling studies.
          </section>
        </section>
      </section>
    );
  }
}
